import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["input", "datalist", "hiddenInput"]

  connect() {
    if (this.hiddenInputTarget.value) {
      const selectedOption = this.datalistTarget.querySelector(`option[data-value="${this.hiddenInputTarget.value}"]`)
      if (selectedOption) {
        this.inputTarget.value = selectedOption.value
      }
    }
  }

  updateValue() {
    const selectedOption = this.datalistTarget.querySelector(`option[value="${this.inputTarget.value}"]`)
    if (selectedOption) {
      this.hiddenInputTarget.value = selectedOption.dataset.value
    } else {
      this.hiddenInputTarget.value = ''
    }
  }
}
